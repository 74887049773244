import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import home from "../../../../../assets/icons/home.png"
import info from "../../../../../assets/icons/info.png"
import founder from "../../../../../assets/icons/teamwork.png"
import current from "../../../../../assets/icons/diversity.png"
import coming from "../../../../../assets/icons/event.png"
import gallery from "../../../../../assets/icons/image.png"
import complete from "../../../../../assets/icons/red-carpet.png"
import contact from "../../../../../assets/icons/customer-service.png"
import donate from "../../../../../assets/icons/reimbursement.png"
import download from "../../../../../assets/icons/download.png"







const Navbar = () => {
  const navigate = useNavigate()
    const data = [
        {
            parent_category:{
              id:1,
              name:"Home",
              img_url:home,
              to:'/'
            },
  
          },
        {
            parent_category:{
              id:1,
              name:"About Us",
              img_url:info,
              to:'/about'
            },
  
          },
       
        {
            parent_category:{
              id:1,
              name:"Members",
              img_url:current,
              to:'/members'

            },
  
          },
        {
          parent_category:{
            id:1,
            name:"Events",
            img_url:coming,
            to:'/events'

          },

        },
        {
          parent_category:{
            name:"Download",
            img_url:download ,
            to:'/download'

          }
        },
       
        {
          parent_category:{
            name:"Gallery",
            img_url:gallery ,
            to:'/gallery'

          }
        },
      
        {
          parent_category:{
            name:"Contact Us",
            img_url:contact,
            to:'/contact'

          }
            
        },
        {
          parent_category:{
            name:"Donate Now",
            img_url:donate,
            to:'/donate'

          }
            
        }
    
    
    
      ]
  return (
    <div>
         <div div className='category d-flex flex-column align-items-center position-relative'>
    <div className='parent-category  bg-pink w-100 h-3 pos-relative d-flex aalign-items-center justify-content-center gap-3 fs-5 fw-500'>
    {
data.map((elem)=>{
  return (
    <>
     <div onClick={()=>navigate(elem.parent_category.to)} className="color-white h-100-per cr-pointer d-flex align-items-center justify-center-center text-decoration-none">
     <div className='d-flex align-items-center justify-content-center gap-2'>
          <div className="d-flex h-m-2 w-m-2 p-1 rounded-circle bg-white align-items-center justify-content-center">
          <img src={elem.parent_category.img_url} className='w-100' alt="" />
          </div>
         <span> {elem.parent_category.name} </span>
     </div>
    
      </div>

    </>
  )

})



    }

    </div>


   
  
  
  </div>
    </div>
  )
}

export default Navbar