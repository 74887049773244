import React, { useState } from 'react'
import DonateBanner from '../../helper/DonateBanner'
import complete from "../../../../assets/icons/red-carpet.png"
import coming from "../../../../assets/icons/event.png"
import Table from '../../helper/Table/Table'
import { pastEvents } from '../../../../data'


const Events = () => {
  const [state,setState] = useState(1)

  return (
    <div className='w-100'> 
    
    <DonateBanner/>
    
    <div className="d-flex w-100 shadow-theme-1 fw-bold text-center fs-5">
    <div className={`w-50 ${state===1? ' bg-pink color-white':'color-orange-red'}  p-2  d-flex gap-3 align-items-center justify-content-center cr-pointer`} onClick={()=>setState(1)} ><img src={coming} alt="" className='w-2' /> Up Comming Events</div>
    <div className={`w-50 ${state===2? ' bg-pink  color-white':'color-orange-red'}  p-2  d-flex gap-3 align-items-center justify-content-center cr-pointer`}  onClick={()=>setState(2)}><img src={complete} alt="" className='w-2' /> Complete Events</div>

</div>




<div className="w-100 p-3">  
{
    state === 1 &&  <Table data = {[]}/>
  }
  {
    state === 2 &&  <Table data = {pastEvents}/>
  }     
              
            </div>

    </div>
  )
}

export default Events