import { Box, Typography } from '@mui/material'
import React from 'react'

const DonateNow = () => {
  return (
    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}} className="w-100  p-3 d-flex gap-3 bg-theme" minHeight="100vh">
        <Typography variant='h4' fontWeight="bold" className='color-orange-red'>Under Maintainance</Typography>
    </Box>
  )
}

export default DonateNow