import React from 'react'

const Map = () => {
  return (
    <div className='h-40 w-100 my-5'>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3628.3036757789414!2d80.87687837481765!3d24.578713856474764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39847e8a61824133%3A0x9687ff2e793af10b!2z4KS44KSw4KSm4KS-4KSwIOCkrOCksuCljeCksuCkrSDgpK3gpL7gpIgg4KSq4KSf4KWH4KSyIOCkuOCljeCkruCkvuCksOCklSDgpJ_gpY3gpLDgpLjgpY3gpJ8sIOCkuOCkpOCkqOCkviDgpK7gpKfgpY3gpK_gpKrgpY3gpLDgpKbgpYfgpLY!5e0!3m2!1sen!2sin!4v1700414032429!5m2!1sen!2sin" width="100%" height="100%" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Map