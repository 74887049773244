import './App.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import BaseRoutes from './BaseRoutes';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'rc-slider/assets/index.css';

function App() {
  return (
    <div className="App">
     <BaseRoutes/>
    </div>
  );
}

export default App;
