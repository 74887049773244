import React from 'react'

const MemberCard = ({item}) => {
  return (
    <div>

<div className="card-head w-100 px-3 ">
    <div className="card cr-pointer h-100-per w-100 card-border overflow-hidden d-flex ">
     <div className="card-img h-40 p-3 d-flex align-items-center justify-content-center overflow-hidden">
         <img src={`${item.img_url}`} alt=""className='w-100 h-100-per'/>
     </div>
     <div className="card-body text-center bg-pink-1 text-white">
         <div className="card-title fw-bold fw-600 ">
             {item.name}
         </div> 
         <div className="card-title fw-bold fw-600 ">
             {item.founder}
         </div>    
        
        
     </div>
     
     </div>
            </div>  
    </div>
  )
}

export default MemberCard