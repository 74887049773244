import React from "react";
import Banner from "../../helper/Banner";
import Heading from "../../helper/Heading";
import FirstCard from "../../helper/Cards/FirstCard";
import Slider from "react-slick";
import MemberCard from "../../helper/Cards/MemberCard";
import Map from "../../helper/Map";
import Information from "./Information";
import { NextArrow, PrevArrow } from "../../helper/Arrow";
import { currentMember, founderMember } from "../../../../data";

const Home = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div>
      <Banner />
      <Heading name="Welcome to Sardar Vallabh Bhai Patel Smarak Trust Satna (M.P.)" />
      <FirstCard />
      <Heading name="Founder Members" />
      <div className="px-5">
        <Slider {...settings}>
          {founderMember.map((elem) => {
            return (
              <>
                <MemberCard item={elem} />
              </>
            );
          })}
        </Slider>
      </div>
      <Heading name="Current Members" />
      <div className="px-5">
        <Slider {...settings}>
          {currentMember.map((elem) => {
            return (
              <>
                <MemberCard item={elem} />
              </>
            );
          })}
        </Slider>
      </div>

      <Heading name="Informations" />
      <Information />
      <Map />
    </div>
  );
};

export default Home;
