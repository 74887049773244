import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

export default function Table({ data }) {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (img) => {
    setSelectedImage(img);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'photo',
      headerName: 'Photo',
      width: 300,
      renderCell: ({ row }) => (
        <Box sx={{ width: 100, display: 'flex', gap: 1 }}>
          <img
            style={{ height: '100%', width: '100%', cursor: 'pointer' }}
            src={row?.img1}
            alt="img1"
            onClick={() => handleOpen(row?.img1)} // Open modal on click
          />
          <img
            style={{ height: '100%', width: '100%', cursor: 'pointer' }}
            src={row?.img2}
            alt="img2"
            onClick={() => handleOpen(row?.img2)} // Open modal on click
          />
        </Box>
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 250,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 750,
    },
  ];

  return (
    <>
      <Box sx={{ height: 700, width: '100%' }}>
        <DataGrid
          sx={{
            color: '#807474',
            fontWeight: 600,
            borderColor: '#adaaaa',
            "& .MuiDataGrid-columnHeaders": {
              fontWeight: 600,
              borderRadius: 'var(--none, 0px)',
              borderBottom: '1px solid var(--divider, rgba(0, 0, 0, 0.12))',
              background: 'var(--primary-selected, rgb(255,4,112))',
              alignItems: 'center',
            },
          }}
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableCheckboxSelection
          disableRowSelectionOnClick
          rowHeight={80}
        />
      </Box>

      {/* Modal for displaying the larger image */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            outline: 'none',
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              style={{
                maxWidth: '100%',
                maxHeight: '80vh',
                objectFit: 'contain',
              }}
            />
          )}
        </Box>
      </Modal>
    </>
  );
}
