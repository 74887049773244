import React from 'react'
import {CCarousel,CCarouselItem,CCarouselCaption,CImage} from '@coreui/react'
import banner1 from '../../../assets/banners/banner1.png'
import banner2 from '../../../assets/banners/banner2.png'
import banner3 from '../../../assets/banners/banner3.png'




const Banner = () => {
  return (
    <div>
        <CCarousel controls dark>
  <CCarouselItem>
    <CImage className="d-block w-100" src={banner1} alt="slide 1" />
   
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={banner2} alt="slide 2" />
   
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={banner3} alt="slide 3" />
  </CCarouselItem>
</CCarousel>
    </div>
  )
}

export default Banner