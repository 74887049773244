import React from 'react'

const Heading = ({name}) => {
  return (
    <div className='fs-3 color-orange-red text-center my-5 fw-bold'>
        {name}
    </div>
  )
}

export default Heading