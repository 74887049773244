export const founderMember = [
    {
      name: "नेता जी महेंद्र सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/927266_Netaji_Mahendra_Singh.jpg",
    },
    {
      name: "लाल जी भाई पटेल",
      founder: "संस्थापक सदस्य",
      img_url: "/images/698055_Lalji_bhai_patel.jpg",
    },
    {
      name: "तोशन सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/9663015_ToshanSingh.jpg",
    },
    {
      name: "बैजनाथ प्रसाद पटेल",
      founder: "संस्थापक सदस्य",
      img_url: "/images/458441Baijnath_Prasad_Patel.jpg",
    },
    {
      name: "रामकरण सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/722411_RamkaranSingh.jpg",
    },
    {
      name: "चौधरी महेंद्र सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/650612_Chaudhary_Mahendra_singh.jpg",
    },

    {
      name: "छोटेलाल सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/514503_Chhotelal_singh.jpg",
    },

    {
      name: "गोविंद सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/134104_Govind Singh.jpg",
    },

    {
      name: "प्रताप सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/453057_Pratap_Singh.jpg",
    },
    {
      name: "आर.डी. सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/229748_RD_Singh.jpg",
    },
    {
      name: "रामाधार सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/891849_Ramadhar_Singh(munshi ji).jpg",
    },
    {
      name: "रामदेव सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/4907410_RamdevSingh.jpg",
    },

    {
      name: "रामनिरंजन सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/4028412_RamniranjanSingh.jpg",
    },
    {
      name: "शारदा सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/6920413_Sharda_Singh.jpg",
    },

    {
      name: "सुंदरलाल सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/3498014_Sunderlal_Singh.jpg",
    },
  ];

  export const currentMember = [
    {
      name: "तोशन सिंह",
      founder: "अध्यक्ष",
      img_url: "/images/848171-Toshan Singh (Adhyaksh).jpg",
    },
    {
      name: "चौधरी महेंद्र सिंह",
      founder: "उपाध्यक्ष",
      img_url: "/images/967522-Chaudhary Mahendra singh (upadhayaksh).jpg",
    },
    {
      name: "आर.डी. सिंह",
      founder: "सचिव",
      img_url: "/images/472903-R. D. Singh (Koshadhyaksh).jpg",
    },
    {
      name: "रामनिरंजन सिंह",
      founder: "कोषाध्यक्ष",
      img_url: "/images/396864-Ramniranjan singh (Sachiv).jpg",
    },
    {
      name: "छोटेलाल सिंह",
      founder: "संस्थापक सदस्य",
      img_url: "/images/93991Chhotelal_singh.jpg",
    },
    {
      name: "प्रताप सिंह",
      founder: "सदस्य",
      img_url: "/images/95872Pratap Singh.jpg",
    },

    {
      name: "रामकरण सिंह",
      founder: "सदस्य",
      img_url: "/images/51524RamkaranSingh.jpg",
    },

    {
      name: "डॉ अभयराज सिंह",
      founder: "सदस्य",
      img_url: "/images/64532Dr. Abhayraj singh.jpg",
    },

    {
      name: "शारदा सिंह",
      founder: "सदस्य",
      img_url: "/images/68048Sharda_Singh.jpg",
    },
    {
      name: "अजयपाल सिंह",
      founder: "सदस्य",
      img_url: "/images/97733Ajaypal singh.jpg",
    },
    {
      name: "ब्रजेंद्र सिंह",
      founder: "सदस्य",
      img_url: "/images/68863Brajendra Singh.jpg",
    },
    {
      name: "गेंदलाल पटेल",
      founder: "सदस्य",
      img_url: "/images/6946Gendlal patel.jpg",
    },

    {
      name: "राकेश प्रताप सिंह",
      founder: "सदस्य",
      img_url: "/images/85709Rakesh Pratap Singh.jpg",
    },
    {
      name: "उपेंद्र सिंह",
      founder: "सदस्य",
      img_url: "/images/40477Upendra singh.jpg",
    },

   
  ];

  export const pastEvents = [
    { id: 1, img1: "/images/33327151215_1.jpg",img2:"/images/18115ABOUTUS.JPG", title: '66वी पुण्यतिथि', date: '15-12-2015', description: 'सरदार वल्लभ भाई पटेल स्मारक ट्रस्ट प्रांगड़ में मनी 66वी पुण्यतिथि। मुख्य अतिथि - श्रीमान डॉ हर्षवर्धन श्रीवास्तव जी (कुलपति - ए के ऐस विश्वविधलया सतना मध्य प्रदेश )  एवं श्रीमान जय प्रकाश पटेल जी (पूर्व विधायक पन्ना )' },
    { id: 2,  img1: "/images/alakhjagai.jpeg",img2:"/images/alakh.jpeg", title: 'सरदार पटेल के विचारो की अलख जगाई', date: '14-03-2016 से 20-03-2016', description: 'सरदार वल्लभ भाई पटेल स्मारक ट्रस्ट ने उठाया प्रचार प्रसार का बीड़ा किया विचारो की अलख जगाई '},
    { id: 3,  img1: "/images/bhumipoojan.jpeg",img2:"/images/7721714102016_1.jpg", title: 'पुस्तकालय भवन का भूमिपूजन', date: '14-10-2016', description: 'माननीय सांसद श्री गणेश सिंह जी ने किया पुष्तकालय का भूमिपूजन।  संसद निधि से तैयार होगा सरदार पटेल वाचनालय' },
    { id: 4,  img1: "/images/lauhpurush.jpeg",img2:"/images/7081731102016_1.jpg", title: '141 वी जयंती', date: '31-10-2016', description: 'स्मारक ट्रस्ट ने मनाया लोह पुरुष की वी जयंती। मुख्य अतिथि - डॉ कपिलदेव मिश्रा (कुलपति रानी दुर्गावती विश्वविद्यालय जबलपुर) ' },
    { id: 5,  img1: "/images/111223.jpeg",img2:"/images/68923311017_1.jpg", title: '142वी जयंती समारोह एवं निःशुल्क नेत्र एवं बी.पी. शुगर जांच शिविर', date: '31-10-2017', description: 'सरदार वल्लभ भाई पटेल स्मारक ट्रस्ट के तत्वाधान में निःशुल्क नेत्र एवं बी.पी. शुगर परिक्षण शिविर तथा जयंती समारोह का आयोजन|शिविर में ख्याति प्राप्त वरिष्ठ नेत्र रोग विशेषज्ञ डॉ चारुलता साई जी एवं अनुभवी होम्योपैथिक चिकित्सक डॉ अशोक अग्रवाल जी |' },
    { id: 6,  img1: "/images/11123.jpeg",img2:"/images/6412122122016_2.jpg", title: '67 वी पुण्यतिथि', date: '22-12-2016', description: 'सरदार वल्लभ भाई पटेल स्मारक ट्रस्ट सतना के तत्वाधान में सरदार पटेल जी की 67वी पुण्यतिथि पखवाड़ा समारोह। मुख्यातिथि - माननीय मनसुखलाल माण्डवीय (केंद्रीय राज्य मंत्री सड़क परिवहन एवं राजमार्ग मंत्रालय भारत सरकार)विशिस्ट अतिथि के रूप में माननीय गणेश सिंह (सांसद सतना), माननीय रामानंद सिंह (पूर्व मंत्री) , माननीय सईद अहमद (पूर्व मंत्री), माननीय लक्समी यादव (सदस्य पिछड़ा वर्ग आयोग), माननीय तोसन सिंह (पूर्व विधायक) माननीय सुधा सिंह (जिला पंचायत अध्यक्ष ), माननीय रामखेलावन पटेल (पूर्व विधायक), माननीय रामलखन पटेल (पूर्व विधायक), माननीय जयप्रकाश पटेल (पूर्व विधायक), माननीय शीला सिंह (मंडी अध्यक्ष) माननीय रश्मि सिंह (जिला पंचायत उपाध्यक्ष )' },
   
  ];

  export const itemData = [
    {
      img: '/images/27366ABOUTUS.jpg',
      title: 'image',
    },
    {
      img: '/images/91852au.png',
      title: 'image',
    },
    {
      img: '/images/60231Innaugration.jpg',
      title: 'image',
    },
    {
      img: '/images/54675kbv.png',
      title: 'images',
    },
    {
      img: '/images/67324md.png',
      title: 'images',
    },
    {
      img: '/images/33327151215_1.jpg',
      title: 'images',
    },
    {
      img: '/images/bhumipoojan.jpeg',
      title: 'images',
    },
    {
      img: '/images/alakhjagai.jpeg',
      title: 'images',
    },
    {
      img: '/images/7721714102016_1.jpg',
      title: 'images',
    },
    {
      img: '/images/6412122122016_2.jpg',
      title: 'images',
    },
    {
      img: '/images/11123.jpeg',
      title: 'images',
    },
    {
      img: '/images/2315922122016_1.jpg',
      title: 'images',
    },
    {
      img: '/images/8011731102016_1.jpg',
      title: 'images',
    },
    
  ];