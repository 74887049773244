import React from "react";
import { itemData, pastEvents } from "../../../../data";
import { useNavigate } from "react-router-dom";

const Information = () => {
    const navigate = useNavigate()
  return (
    <div>
      <section class="spacet100 spaceb100">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-12">
              <div class="shadow h-80 rounded-4 overflow-hidden position-relative">
                <div class="eventstitle  bg-pink-1 fw-bold text-white text-center border-1 h-3 fs-4 d-flex align-items-center justify-content-center">
                  <p>Past Event</p>
                </div>
                <div className="eventcontent h-55 px-4">
                  <marquee
                    behavior=""
                    direction="up"
                    width="100%"
                    className="h-100-per"
                  >
                    <div className="w-100 d-flex align-items-center flex-column gap-3 justify-content-center">
                      {pastEvents.map((elem) => (
                        <div className="btn btn-lg bg-pink-1 text-white w-100">
                          {elem?.title}
                        </div>
                      ))}
                    </div>
                  </marquee>
                </div>
                <div className="read-more px-4 h-6 d-flex align-items-center justify-content-center">
                  <div className="btn btn-lg text-white w-100 bg-pink-1" onClick={()=> navigate("/members")}>
                    Read More
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="shadow h-80 rounded-4 overflow-hidden position-relative">
                <div class="eventstitle bg-pink-1 fw-bold text-white text-center border-1 h-3 fs-4 d-flex align-items-center justify-content-center">
                  <p>Upcoming Events</p>
                </div>
                <div className="eventcontent h-55 px-4">
                  <marquee
                    behavior=""
                    direction="up"
                    width="100%"
                    className="h-100-per"
                  >
                    <div className="w-100 d-flex align-items-center flex-column gap-3 justify-content-center">
                      <div className="btn btn-lg bg-pink-1 text-white w-100">
                        Under Development
                      </div>
                      <div className="btn btn-lg bg-pink-1 text-white w-100">
                        Under Development
                      </div>
                    </div>
                  </marquee>
                </div>
                <div className="read-more px-4 h-6 d-flex align-items-center justify-content-center">
                  <div className="btn btn-lg text-white w-100 bg-pink-1" onClick={()=> navigate("/members")}>
                    Read More
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="shadow h-80 rounded-4 overflow-hidden position-relative">
                <div class="eventstitle bg-pink-1 fw-bold text-white text-center border-1 h-3 fs-4 d-flex align-items-center justify-content-center">
                  <p>Photo Gallery</p>
                </div>
                <div className="eventcontent h-55 px-4 ">
                  <marquee
                    behavior=""
                    direction="up"
                    width="100%"
                    className="h-100-per"
                  >
                    <div className="w-100 d-flex align-items-center flex-column gap-2 justify-content-center">
                      
                      {
                        itemData.map((elem) => (
                            <div className="card  bg-pink-1 text-white w-50 p-1">
                            <img
                              src={elem.img}
                              alt=""
                            />
                          </div>
                        ))
                      }
                     
                      
                    </div>
                  </marquee>
                </div>
                <div className="read-more px-4 h-6 d-flex align-items-center justify-content-center">
                  <div className="btn btn-lg text-white w-100 bg-pink-1" onClick={()=> navigate("/gallery")}>
                    Read More
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Information;
