import React from 'react'
import TopHead from './TopHead'
import Navbar from './Navbar/Navbar'

const Header = () => {
  return (
    <div>
    <TopHead/>
    <Navbar/>
    </div>
  )
}

export default Header