import React from 'react'
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Home from './views/users/views/Home/Home'
import Header from './views/users/helper/Header/Header'
import Footer from './views/users/helper/Footer/Footer'
import About from './views/users/views/About/About'
import Member from './views/users/views/Member/Member'
import Events from './views/users/views/Events/Events'
import Gallery from './views/users/views/Gallery/Gallery'
import ConactUs from './views/users/views/ContactUs/ConactUs'
import DonateNow from './views/users/views/DonateNow/DonateNow'
import Download from './views/users/views/Download/Download'


const BaseRoutes = () => {
  return (
    <div>
        <BrowserRouter>
        <Header/>
           <Routes>
            <Route exact path='/' name="Home" element={<Home/>} />
            <Route exact path='/about' name="About" element={<About/>} />
            <Route exact path='/members' name="Members" element={<Member/>} />
            <Route exact path='/events' name="Events" element={<Events/>} />
            <Route exact path='/download' name="Download" element={<Download/>} />
            <Route exact path='/gallery' name="Gallery" element={<Gallery/>} />
            <Route exact path='/contact' name="Contact" element={<ConactUs/>} />
            <Route exact path='/donate' name="Donate" element={<DonateNow/>} />











           </Routes>
        
        <Footer/>
        </BrowserRouter>


    </div>
  )
}

export default BaseRoutes