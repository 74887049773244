import { Box, Typography } from "@mui/material";
import React from "react";
import telephone from "../../../../assets/icons/telephone.png";
import email from "../../../../assets/icons/message.png";
import loc from "../../../../assets/icons/pin.png";

const ConactUs = () => {
  return (
    <Box className="w-100  p-3 d-flex gap-3" minHeight="100vh">
      <Box
        maxWidth={500}
        minWidth={500}
        height={250}
        borderRadius={2}
        bgcolor="red"
        className="bg-pink-1"
        p={2}
        display="flex"
        flexDirection="column"
        gap={3}
      >
        <h4 style={{color:"white"}}>Address Card -</h4>
        <div className="row gap-2 align-items-center">
          <div className="col-1">
            <Box
              className="d-flex align-items-center justify-content-center"
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "5px",
                background: "white",
              }}
            >
              {" "}
              <img src={loc} alt="" className="w-m-2" />
            </Box>
          </div>
          <div
            className="col-10"
            style={{ color: "white", fontWeight: "bold", fontSize: 18 }}
          >
            OFFICE: Near Central Jail , Behind Medical College Satna (M.P.)
            485001
          </div>
        </div>

        <div className="row gap-2 align-items-center">
          <div className="col-1">
            <Box
              className="d-flex align-items-center justify-content-center"
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "5px",
                background: "white",
              }}
            >
              {" "}
              <img src={loc} alt="" className="w-m-2" />
            </Box>
          </div>
          <div
            className="col-10"
            style={{ color: "white", fontWeight: "bold", fontSize: 18 }}
          >
            CORRESPONDENCE ADDRESS: Gali No:2, Friends Colony, Satna (M.P.)
          </div>
        </div>
      </Box>

      <Box
        maxWidth={500}
        minWidth={500}
        height={250}
        borderRadius={2}
        bgcolor="red"
        className="bg-pink-1"
        p={2}
        display="flex"
        flexDirection="column"
        gap={3}
      >
        <h4 style={{color:"white"}}>Contact Card -</h4>
        <div className="row gap-2 align-items-center">
          <div className="col-1">
            <Box
              className="d-flex align-items-center justify-content-center"
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "5px",
                background: "white",
              }}
            >
              {" "}
              <img src={telephone} alt="" className="w-m-2" />
            </Box>
          </div>
          <div
            className="col-10"
            style={{ color: "white", fontWeight: "bold", fontSize: 18 }}
          >
            09981152270, 09179059653, 09109623784, 9893395422 6264587037
          </div>
        </div>

        <div className="row gap-2 align-items-center">
          <div className="col-1">
            <Box
              className="d-flex align-items-center justify-content-center"
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "5px",
                background: "white",
              }}
            >
              {" "}
              <img src={email} alt="" className="w-m-2" />
            </Box>
          </div>
          <div
            className="col-10"
            style={{ color: "white", fontWeight: "bold", fontSize: 18 }}
          >
            info@sardarvallabhbhaipateltrust.com ,svbpstsatna123@gmail.com
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default ConactUs;
